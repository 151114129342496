const logoPaths = [
  {
    id: 0,
    path: 'M5.45362 25.1214H6.89012L10.9835 45.4504H16.4371L11.9369 22.5891L16.0939 0.366852H10.6403L6.87741 20.0569H5.45362V0.366852H0V45.4504H5.45362V25.1214Z',
    viewBox: '0 0 17 46',
    width: 17,
    height: 46
  },
  {
    id: 1,
    path: 'M14.0987 40.3859H5.91196V25.4409H12.0521V20.3764H5.91196V5.43135H14.0987V0.366852H0.458344V45.4504H14.0987V40.3859Z',
    viewBox: '0 0 15 46',
    width: 15,
    height: 46
  },
  {
    id: 2,
    path: 'M13.9893 40.3859H5.7898V25.4409H11.9426V20.3764H5.7898V5.43135H13.9893V0.366852H0.348892V45.4504H13.9893V40.3859Z',
    viewBox: '0 0 14 46',
    width: 14,
    height: 46
  },
  {
    id: 3,
    path: 'M13.7781 2.42575C12.3798 1.05312 10.4602 0.35498 8.07024 0.35498H0.226685V45.4385H5.6676V32.1146H8.07024C10.4348 32.1146 12.2781 31.4401 13.7018 30.0438C15.1256 28.6594 15.8375 26.7898 15.8375 24.4942L15.9011 7.9754C15.9138 5.69164 15.2019 3.82203 13.7781 2.42575ZM5.61675 27.0383V5.43131H7.53632C9.12537 5.43131 10.4093 6.62644 10.4093 8.10556V24.364C10.4093 25.8431 9.12537 27.0383 7.53632 27.0383H5.61675V27.0383Z',
    viewBox: '0 0 16 46',
    width: 16,
    height: 46
  },
  {
    id: 4,
    path: 'M16.9824 0.366852L12.1008 32.6945L7.21924 0.366852H0.367257L0.214706 45.4504H5.66833L5.68104 17.4773L9.59646 45.4504H14.6051L18.5079 17.4773L18.5333 45.4504H23.9869L23.8216 0.366852H16.9824Z',
    viewBox: '0 0 24 46',
    width: 24,
    height: 46
  },
  {
    id: 5,
    path: 'M13.9664 2.07077C12.5935 0.72181 10.7247 0.0354988 8.39837 0H8.25853C5.94487 0.0236659 4.06343 0.72181 2.69049 2.07077C1.2667 3.45522 0.55481 5.32483 0.55481 7.62042V38.1968C0.55481 40.4924 1.27942 42.362 2.69049 43.7464C4.11428 45.1309 5.95758 45.8172 8.32209 45.8172C10.6866 45.8172 12.5299 45.1427 13.9537 43.7464C15.3775 42.362 16.0894 40.4924 16.0894 38.1968V7.62042C16.1148 5.32483 15.3902 3.45522 13.9664 2.07077ZM6.00844 5.07633H10.801V40.7408H6.00844V5.07633Z',
    viewBox: '0 0 17 46',
    width: 17,
    height: 46
  },
  {
    id: 6,
    path: 'M16.4842 14.9213V7.62042C16.4842 5.32483 15.7596 3.45522 14.3485 2.07077C12.9501 0.698144 11.0306 0 8.64064 0C6.25071 0 4.34387 0.698144 2.93279 2.07077C1.509 3.45522 0.797104 5.32483 0.797104 7.62042C0.797104 9.90418 1.05135 11.4188 1.55985 13.4541C2.04292 15.371 2.66582 17.1814 3.41585 18.8381C4.16588 20.4947 5.0049 22.1039 5.88206 23.6304C6.82278 25.2515 7.62365 26.7661 8.32283 28.2452C9.06015 29.8072 9.68306 31.4638 10.1534 33.1559C10.5729 34.6705 10.8145 38.2086 10.878 40.6343H6.09816V29.5942H0.644562V38.0903C0.644562 40.3858 1.36916 42.2555 2.78023 43.6399C4.20402 45.0244 6.04732 45.7107 8.41183 45.7107C10.7763 45.7107 12.6196 45.0362 14.0434 43.6399C15.4672 42.2555 16.1791 40.3858 16.1791 38.0903C16.1791 35.8065 15.9248 34.4102 15.4163 32.3749C14.9333 30.458 14.3104 28.6476 13.5603 26.991C12.8103 25.3343 11.984 23.7251 11.0941 22.1986C10.1534 20.5775 9.35255 19.0629 8.65337 17.5838C7.91605 16.0218 7.29314 14.3652 6.82278 12.6731C6.35242 11.0046 6.238 9.80951 6.22529 7.93991V5.07633H11.0179V14.9213H16.4842V14.9213Z',
    viewBox: '0 0 17 46',
    width: 17,
    height: 46
  },
  {
    id: 7,
    path: 'M15.7265 17.3471V7.62042C15.7265 5.32483 15.0019 3.45522 13.5908 2.07077C12.1924 0.698144 10.2729 0 7.88293 0C5.50572 0 3.58616 0.698144 2.17509 2.07077C0.751298 3.45522 0.0393982 5.32483 0.0393982 7.62042V38.1968C0.0393982 40.4924 0.76401 42.362 2.17509 43.7464C3.59887 45.1309 5.44216 45.8172 7.80666 45.8172C10.1712 45.8172 12.0145 45.1427 13.4383 43.7464C14.862 42.362 15.5739 40.4924 15.5739 38.1968V28.3162H10.2602V40.7408H5.46758V5.07633H10.2602V17.3471H15.7265Z',
    viewBox: '0 0 16 46',
    width: 16,
    height: 46
  },
  {
    id: 8,
    path: 'M13.9094 2.07077C12.5364 0.72181 10.6677 0.0354988 8.34132 0H8.20149C5.88783 0.0236659 4.00638 0.72181 2.63344 2.07077C1.20964 3.45522 0.497742 5.32483 0.497742 7.62042V38.1968C0.497742 40.4924 1.22236 42.362 2.63344 43.7464C4.05723 45.1309 5.90054 45.8172 8.26505 45.8172C10.6296 45.8172 12.4729 45.1427 13.8966 43.7464C15.3204 42.362 16.0323 40.4924 16.0323 38.1968V7.62042C16.0578 5.32483 15.3332 3.45522 13.9094 2.07077ZM5.95138 5.07633H10.744V40.7408H5.95138V5.07633Z',
    viewBox: '0 0 17 46',
    width: 17,
    height: 46
  },
  {
    id: 9,
    path: 'M21.9062 0.366852L19.3129 28.1151L16.7196 0.366852H10.9863L8.39296 28.1151L5.79963 0.366852H0.0027771L5.18943 45.4504H10.91L13.8466 18.1044L16.7831 45.4504H22.5037L27.7031 0.366852H21.9062Z',
    viewBox: '0 0 28 46',
    width: 28,
    height: 46
  }
]

export default logoPaths