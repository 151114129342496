import React from 'react'
import { animated as a } from 'react-spring'

import { VideoModalProvider } from '../../../context/VideoModal'
import { ContactModalContext } from '../../../context/ContactModal'

import Link from '../../../components/Link'

import HeroSectionMobile from './sections/HeroSectionMobile'
import ApproachSectionMobile from './sections/ApproachSectionMobile'
import FormSectionMobile from './sections/FormSectionMobile'
import AboutUsSectionMobile from './sections/AboutUsSectionMobile'
import TeamSectionMobile from './sections/TeamSectionMobile'
import ContactSectionMobile from './sections/ContactSectionMobile'

import useInterpConfig from '../../../hooks/interp'

import folder from '../../../assets/svg/folder.svg'

const HomePageMobile = ({ data, spring: { st } }) => {
  const { team } = data
  const [interpConfig] = useInterpConfig()

  return (
    <VideoModalProvider>

      <HeroSectionMobile catalogTitle={data.catalog_title} />
      <ApproachSectionMobile spring={{ st }} />
      <FormSectionMobile />
      <AboutUsSectionMobile spring={{ st }} />
      {team && (
        <TeamSectionMobile team={team} />
      )}
      <ContactSectionMobile />

      <ContactModalContext.Consumer>
        {({ openModal }) => (
          <a.div className="fixed bottom-vw-20 right-vw-12 z-50 rounded-lg px-vw-14 py-vw-10" style={{
            opacity: st.to(interpConfig([
              [400, 0],
              [450, 1],
            ])),
            visibility: st.to(interpConfig([
              [400, 0],
              [450, 1],
            ])).to(o => o ? 'visible' : 'hidden'),
            backgroundColor: '#131313'
          }}>
            <Link text="Скачать каталог" icon={folder} withUnderline={true} onPress={openModal} />
          </a.div>
        )}
      </ContactModalContext.Consumer>

    </VideoModalProvider>
  )
}

export default HomePageMobile
