import React, { useEffect, useRef, useState } from 'react'
import { useSpring, animated as a } from 'react-spring'
import { useMediaQuery } from 'react-responsive'

import bigArrow from '../assets/svg/big-arrow.svg'

const CreatorCard = ({ name, text, image: { src, alt }, instagramLink, className }) => {
  const creatorText = useRef(null)
  const [cardStyles, cardApi] = useSpring(() => ({ y: 0, ty: 1000, opacity: 0, bgOpacity: 1 }))
  const [cardOpen, setCardOpen] = useState(false)
  const isDesktop = useMediaQuery({ query: '(min-width: 640px)' })

  useEffect(() => {
    if (creatorText.current) {
      let y = cardOpen
        ? -creatorText.current.offsetHeight
        : 0
      let ty = cardOpen
        ? Math.abs(y + creatorText.current.offsetHeight)
        : 1000

      cardApi.start({
        y: y,
        ty: ty,
        opacity: cardOpen ? 1 : 0,
        bgOpacity: cardOpen ? 0.1 : 1
      })
    }
  }, [cardOpen, cardApi])

  return (
    <div
      className={`flex h-full bg-black relative xl:px-vw-8 px-vw-14 xl:py-vw-14 py-vw-24 ${className ? className : ''}`}
      {...(isDesktop ? {
        onPointerEnter: () => setCardOpen(true),
        onPointerLeave: () => setCardOpen(false)
      } : {})}
    >
      <a.img
        className="w-full h-full absolute inset-0 object-cover object-top"
        src={src}
        alt={alt}
        style={{ opacity: cardStyles.bgOpacity }}
      />

      <div className="flex flex-col h-full relative z-10">
        <div ref={creatorText} className="flex flex-grow xl:h-[23vw] h-[80vw] overflow-hidden">
          <a.div className="xl:pt-vw-16 pt-vw-56" style={{
            opacity: cardStyles.opacity,
            translateY: cardStyles.ty
          }}>
            <p className="text xl:text-vw-xl text-vw-base font-extralight normal-case">
              { text }
            </p>
          </a.div>
        </div>

        <a.button className="text-left underline mt-auto" onClick={() => setCardOpen(!cardOpen)} style={{
          translateY: cardStyles.y
        }}>
          <h4 className="text xl:text-vw-4xl text-vw-2xl">
            { name }
          </h4>
        </a.button>

        <div className="flex w-full justify-between xl:mt-vw-8 mt-vw-40">
          <button className="flex items-center text text-vw-lg underline" {...(!isDesktop ? {
            onClick: () => setCardOpen(!cardOpen)
          } : {})}>
            <a.img
              className="xl:w-vw-3 xl:h-vw-3 w-vw-16 h-vw-16 filter invert xl:mr-vw-2 mr-vw-16"
              src={bigArrow}
              alt=""
              style={{
                rotate: cardStyles.opacity.to(o => o * -90)
              }}
            />
            Подробнее
          </button>

          <a
            className="text text-vw-lg underline"
            target="_blank"
            rel="noreferrer"
            href={`${instagramLink}?utm_medium=website&utm_source=keepmoscow%20website`}
          >
            Instagram
          </a>
        </div>
      </div>
    </div>
  )
}

export default CreatorCard
