import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import PagesLoadingWrapper from '../../components/PagesLoadingWrapper';

import imgDesktop from '../../assets/img/bot/botpage-desktop-bg.png';
import imgMobile from '../../assets/img/bot/botpage-mobile-bg.png';
import logo from '../../assets/svg/logo.svg';
import telegram from '../../assets/svg/telegram.svg';


const desktopImages = [imgDesktop];
const mobileImages = [imgMobile];


const BotPage = () => {
  const [pageHeight, setPageHeight] = useState('100vh');
  const [pageState, setPageState] = useState();
  const isDesktop = useMediaQuery({ query: '(min-width: 640px)' })

  useEffect(() => {
    if (!isDesktop) {
      setPageHeight(window.innerHeight);
    }

    const imagePromises = (isDesktop ? desktopImages : mobileImages).map(src => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
      })
    })

    setPageState(Promise.all(imagePromises))
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PagesLoadingWrapper pageState={pageState}>
      <main className="w-screen overflow-hidden" style={{ height: pageHeight, background: 'linear-gradient(125deg, rgb(8, 11, 15), rgba(27, 30, 38, 0.3) 99%)' }}>
        {isDesktop ? (

          <section className="w-full h-full relative pt-vw-14">
            <img className="w-[57vw] max-w-6xl mx-auto" src={logo} alt="keepmoscow logo" />

            <div className="flex h-full absolute bottom-0 left-1/2 -translate-x-1/2">
              <img
                className="max-w-7xl h-[98%] object-contain object-bottom mt-auto mr-vw-20"
                src={imgDesktop}
                alt="keepmoscow bot maksim pavel"
              />
            </div>

            <a className="flex absolute left-1/2 bottom-vw-28 -translate-x-1/2 hover:scale-105 transition-transform" href="https://t.me/kpmscbot">
              <div className="flex items-center h-vw-16 bg-gray px-vw-20">
                <p className="text-vw-3xl uppercase">Погнали</p>
              </div>

              <div className="flex justify-center items-center w-vw-20 h-vw-16 bg-orange">
                <img className="w-vw-8" src={telegram} alt="telegram logo" />
              </div>
            </a>

            <h1 className="text text-vw-3xl whitespace-nowrap absolute left-1/2 bottom-vw-32 -translate-x-1/2 ml-[33vw]">
              Узнай <span className="text-orange">за 19,5 минут</span> как<br />
              правильно выбрать<br />
              недвижимость <span className="text-orange">для<br />жизни и инвестиций</span><br />
              в Москве
            </h1>

            <div className="w-px bg-white absolute top-vw-7 left-vw-12 bottom-vw-7" />
            <div className="w-px bg-white absolute top-vw-7 left-1/2 bottom-vw-7 -translate-x-1/2 z-[-1]" />
            <div className="w-px bg-white absolute top-vw-7 right-vw-12 bottom-vw-7" />
          </section>

        ) : (

          <section className="w-full h-full relative pt-vw-28">
            <img className="w-[83vw] mx-auto" src={logo} alt="keepmoscow logo" />

            <div className="flex flex-col items-start w-[64.5vw] h-full border-l-px border-white px-vw-12 pt-vw-7 mt-vw-32 ml-auto">
              <h1 className="text-mobile text-vw-base whitespace-nowrap mb-vw-14">
                  Узнай <span className="text-orange">за 19,5 минут</span> как<br />
                  правильно выбрать<br />
                  недвижимость <span className="text-orange">для<br />жизни и инвестиций</span><br />
                  в Москве
              </h1>
            </div>

            <img
              className="w-full absolute bottom-0 left-0"
              src={imgMobile}
              alt="keepmoscow bot maksim pavel"
            />

            <a className="flex absolute left-1/2 bottom-vw-72 -translate-x-1/2 hover:scale-105 transition-transform" href="https://t.me/kpmscbot">
              <div className="flex items-center h-vw-52 bg-gray px-vw-56">
                <p className="text-vw-xl uppercase">Погнали</p>
              </div>

              <div className="flex justify-center items-center w-vw-56 h-vw-52 bg-orange">
                <img className="w-vw-24" src={telegram} alt="telegram logo" />
              </div>
            </a>
          </section>

        )}
      </main>
    </PagesLoadingWrapper>
  )
}


export default BotPage
