import React, { useContext } from 'react'
import VideoPreview from '../../../../components/VideoPreview'

import { VideoModalContext } from '../../../../context/VideoModal'
import { ContactModalContext } from '../../../../context/ContactModal'

import Link from '../../../../components/Link'
import MainForm from '../../../../components/MainForm'

import keepmoscowVideo from '../../../../assets/img/contact/webp/mobile/kpmscw-video.webp'
import folder from '../../../../assets/svg/folder.svg'

const ContactSectionMobile = () => {
  const { openVideo } = useContext(VideoModalContext)
  const { openModal } = useContext(ContactModalContext)

  return (
    <section id="contact" className="flex flex-col w-full mt-vw-60">
      <div className="w-full pl-vw-36">
        <div className="w-px h-vw-28 bg-white"></div>
      </div>

      <div className="w-full px-vw-10">
        <VideoPreview
          poster={{
            src: keepmoscowVideo,
            alt: 'keepmoscow video poster'
          }}
          onClick={() => openVideo('B5G77GN32MI')}
        />
      </div>

      <div className="w-full pl-vw-36">
        <div className="flex flex-col items-start w-full border-l-px border-white pl-vw-24">
          <h4 className="text text-vw-xl mt-vw-40">
            Персонализированное<br />
            агентство по подбору<br />
            <span className="text-orange">самой крутой</span><br />
            недвижимости<br />
            <span className="text-orange">Москвы</span>
          </h4>

          <div className="flex flex-col items-start mt-vw-80">
            <h4 className="text text-vw-base">
              ТОП-31<br />комплексов<br />
              Москвы по<br />
              версии<br />
              Keepmoscow
            </h4>


            <Link
              text="Скачать"
              withUnderline={true}
              icon={folder}
              style={{ zIndex: 1, marginTop: '9vw' }}
              onPress={openModal}
            />
          </div>

          <Link
            className="text-vw-xl relative mb-vw-4"
            href="mailto:info@keepmoscow.ru"
            text={<p>info<span className="text-orange">@</span>keepmoscow.ru</p>}
            style={{ marginTop: '28vw' }}
          />
          <Link
            className="text-vw-xl relative mb-vw-28"
            href="tel:+7-499-302-21-06"
            text="+7 (499) 302-21-06"
          />
        </div>
      </div>

      <div className="mt-vw-32 px-vw-16">
        <MainForm
          title={<>Оставить <span className="text-orange">заявку</span></>}
          amoTitle="Оставить заявку"
          style={{ boxShadow: '0px 0px 50px 25px black' }}
        />
      </div>
    </section>
  )
}

export default ContactSectionMobile
