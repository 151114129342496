import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTransition, useSpring, animated as a } from "react-spring";
import { Link, useLocation } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { useMediaQuery } from "react-responsive";

import { API_BASE_URL } from "../App.jsx";

import { makeHtmlFor } from "../utils/gen";

import bigArrow from "../assets/svg/big-arrow.svg";
import checkMark from "../assets/svg/check-mark.svg";
import checkMarkBlack from "../assets/svg/check-mark-black.svg";
import xMarkBlack from "../assets/svg/x-black.svg";

const icons = {
  false: bigArrow,
  success: checkMarkBlack,
  error: xMarkBlack,
};

const colors = {
  false: "#EB682A",
  success: "#73EB2A",
  error: "#ff0000",
};

const MainForm = ({ title, amoTitle, className, withLines = true, afterSuccess, afterSuccessUrl, ...props }) => {
  const [submitted, setSubmitted] = useState(false);
  const [check, setCheck] = useState(true);
  const [htmlFor] = useState(makeHtmlFor(6));
  const isDesktop = useMediaQuery({ query: "(min-width: 640px)" });
  const location = useLocation();

  const transitions = useTransition(submitted, {
    from: {
      opacity: 0,
      translateY: 30,
      scale: 0.9,
      position: "absolute",
    },
    enter: {
      opacity: 1,
      translateY: 0,
      scale: 1,
    },
    leave: {
      opacity: 0,
      translateY: -30,
      scale: 0.9,
      position: "absolute",
    },
  });

  const [buttonStyles, buttonApi] = useSpring(() => ({
    backgroundColor: colors["false"],
  }));

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Обязательный!";
    }

    if (!values.phone) {
      errors.phone = "Обязательный!";
    }

    if (!values.privacy[0]) {
      errors.privacy = "Обязательный!";
    }

    return errors;
  };

  useEffect(() => {
    buttonApi.start({
      backgroundColor: colors[submitted.toString()],
    });
  }, [submitted, buttonApi]);

  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        privacy: check ? ["privacy"] : [],
        amoTitle: amoTitle ? amoTitle : "",
      }}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values) => {
        const ReactPixel = require("react-facebook-pixel");

        const params = new URLSearchParams(location.search);
        values.utm_source = params.get("utm_source") || "";
        values.utm_medium = params.get("utm_medium") || "";
        values.utm_campaign = params.get("utm_campaign") || "";
        values.utm_content = params.get("utm_content") || "";
        values.roistat_visit = window.roistat.getVisit() || "nocookie";

        axios
          .post(API_BASE_URL + "amo/modal/", values)
          .then((response) => {
            setSubmitted("success");

            ReactPixel.default.track("Lead");
            if (typeof VK === "object") {
              VK.Retargeting.Event("form"); // eslint-disable-line
            }

            setTimeout(() => {
              if (afterSuccess) afterSuccess(values);
              if (afterSuccessUrl) window.location.href = afterSuccessUrl;
            }, 2000);
          })
          .catch((err) => {
            console.warn(err);
            setSubmitted("error");
            setTimeout(() => setSubmitted(false), 1500);
          });
      }}
    >
      {({ errors }) => (
        <Form
          className={`flex xl:flex-row flex-col flex-shrink-0 xl:items-center xl:h-[29vw]
            bg-black relative xl:px-0 px-vw-32 ${className ? className : ""}`}
          {...props}
        >
          <div
            className="flex flex-shrink-0 xl:justify-center items-center xl:w-[19.3vw] xl:h-full h-vw-64
              bg-black relative"
          >
            <p className="text xl:text-vw-4xl text-vw-xl xl:normal-case">{title}</p>

            {withLines && <div className="xl:block hidden w-px absolute top-vw-11 right-0 bottom-vw-11 bg-white"></div>}
          </div>

          <div className="flex flex-col flex-shrink-0 justify-center xl:w-[35vw] h-full relative xl:mt-0 mt-vw-32 xl:px-vw-16">
            <label className="flex flex-col w-full xl:mb-vw-14 mb-vw-36" htmlFor={`name_${htmlFor}`}>
              <p className="text xl:text-vw-xl text-vw-base mb-vw-3">Имя и фамилия</p>
              <Field
                id={`name_${htmlFor}`}
                className={`
                  field
                  w-full xl:h-vw-10 h-vw-32
                  xl:text-vw-base text-vw-sm font-light bg-transparent border-b-px
                  xl:p-vw-3 p-vw-5
                  ${errors.name ? "border-red border-opacity-100" : "border-white border-opacity-50"}
                `}
                type="text"
                name="name"
              />
            </label>

            <label className="flex flex-col w-full xl:mb-vw-14" htmlFor={`phone_${htmlFor}`}>
              <p className="text xl:text-vw-xl text-vw-base mb-vw-3">Номер телефона</p>
              <Field
                id={`phone_${htmlFor}`}
                className={`
                  field
                  w-full xl:h-vw-10 h-vw-32
                  xl:text-vw-base text-vw-sm font-light bg-transparent border-b-px
                  xl:p-vw-3 p-vw-5
                  ${errors.phone ? "border-red border-opacity-100" : "border-white border-opacity-50"}
                `}
                type="tel"
                autoComplete="tel"
                name="phone"
              />
            </label>

            <label className="xl:flex hidden items-center cursor-pointer" htmlFor={`privacy_${htmlFor}`}>
              <Field
                className="hidden"
                id={`privacy_${htmlFor}`}
                type="checkbox"
                name="privacy"
                value="privacy"
                checked={check}
                onClick={() => setCheck(!check)}
              />

              <div
                className={`flex justify-center items-center w-vw-5 h-vw-5
                  border-[2px] ${errors.privacy ? "border-red" : "border-white"}
                  mr-vw-4`}
              >
                <img className="w-vw-3" src={checkMark} alt="" style={{ opacity: check ? 1 : 0 }} />
              </div>

              <p className="text text-vw-base leading-vw-5 font-extralight normal-case text-white text-opacity-50">
                Нажимая на кнопку, вы даёте согласие
                <br />
                на обработку{" "}
                <Link className="text-white underline" to="/privacy-policy">
                  персональных данных
                </Link>
                .
              </p>
            </label>

            <div className="xl:block hidden w-px absolute top-vw-11 right-0 bottom-vw-11 bg-white"></div>
          </div>

          {isDesktop ? (
            <button className="group flex flex-grow h-full" disabled={submitted ? true : false} type="submit">
              <div className="flex justify-center items-center w-vw-72 h-full">
                <p className="text text-vw-4xl normal-case relative">
                  Отправить
                  <span
                    className={`h-[0.4vw] xl:h-[0.1vw] bg-orange absolute left-0 right-0 xl:bottom-0 -bottom-vw-2
                    opacity-0 group-hover:-bottom-vw-0.5 group-hover:opacity-100 transition-all`}
                  />
                </p>
              </div>

              <a.div
                className="flex flex-shrink-0 items-center justify-center w-vw-24 h-full relative ml-auto"
                style={buttonStyles}
              >
                {transitions((styles, subbed) =>
                  subbed ? (
                    <a.img
                      className="w-vw-12 h-vw-12 transform group-hover:scale-110 transition-transform"
                      src={icons[subbed.toString()]}
                      alt=""
                      style={styles}
                    />
                  ) : (
                    <a.img
                      className="w-vw-12 h-vw-12 transform group-hover:scale-110 transition-transform"
                      src={bigArrow}
                      alt=""
                      style={styles}
                    />
                  )
                )}
              </a.div>
            </button>
          ) : (
            <>
              <button
                className="group flex items-center h-vw-48 relative z-20 mt-vw-32 -mx-vw-10"
                disabled={submitted ? true : false}
                type="submit"
              >
                <div className="flex flex-grow items-center h-full bg-gray pl-vw-36">
                  {transitions((styles, subbed) =>
                    ["success", "error"].includes(subbed) ? (
                      <a.p className="text text-vw-lg normal-case" style={styles}>
                        {subbed === "success" ? "Отправлено!" : "Ошибка!"}
                      </a.p>
                    ) : (
                      <a.p className="text text-vw-lg normal-case" style={styles}>
                        Отправить
                      </a.p>
                    )
                  )}
                </div>

                <div className="flex justify-center items-center w-vw-72 h-full relative">
                  {transitions((styles, subbed) =>
                    ["success", "error"].includes(subbed) ? (
                      <a.div
                        className="flex justify-center items-center w-full h-full absolute inset-0"
                        style={buttonStyles}
                      >
                        <a.img
                          className="w-vw-24 h-vw-24 transform group-active:scale-110 transition-transform"
                          src={icons[subbed.toString()]}
                          alt=""
                        />
                      </a.div>
                    ) : (
                      <a.div
                        className="flex justify-center items-center w-full h-full absolute inset-0"
                        style={buttonStyles}
                      >
                        <a.img
                          className="w-vw-24 h-vw-24 transform group-active:scale-110 transition-transform"
                          src={bigArrow}
                          alt=""
                        />
                      </a.div>
                    )
                  )}
                </div>
              </button>

              <label className="flex items-center justify-center w-full py-vw-28" htmlFor={`privacy_${htmlFor}`}>
                <Field
                  className="hidden"
                  id={`privacy_${htmlFor}`}
                  type="checkbox"
                  name="privacy"
                  value="privacy"
                  checked={check}
                  onClick={() => setCheck(!check)}
                />

                <div
                  className={`flex justify-center items-center w-vw-16 h-vw-16
                    border-[1px] ${errors.privacy ? "border-red" : "border-white"}
                    mr-vw-12`}
                >
                  <img className="w-vw-10" src={checkMark} alt="" style={{ opacity: check ? 1 : 0 }} />
                </div>

                <p className="text text-[2.8vw] leading-tight font-extralight normal-case text-white text-opacity-50">
                  Нажимая на кнопку, вы даёте согласие
                  <br />
                  на обработку{" "}
                  <Link className="text-white underline" to="/privacy-policy">
                    персональных данных
                  </Link>
                  .
                </p>
              </label>
            </>
          )}

          {transitions(
            ({ opacity, ...styles }, subbed) =>
              ["success", "error"].includes(subbed) && (
                <a.div
                  className="flex justify-center items-center xl:h-full bg-black xl:bg-opacity-100 bg-opacity-70
                absolute xl:top-0 top-vw-64 bottom-0 left-0 xl:right-vw-24 right-0 z-10"
                  style={{ opacity: opacity, visibility: opacity.to((o) => (o ? "visible" : "hidden")) }}
                >
                  {isDesktop && (
                    <a.p className="text text-vw-4xl normal-case" style={styles}>
                      {subbed === "success" ? "Отправлено!" : "Непредвиденная ошибка!"}
                    </a.p>
                  )}
                </a.div>
              )
          )}
        </Form>
      )}
    </Formik>
  );
};

export default MainForm;
