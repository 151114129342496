import { useEffect } from 'react';


const useScript = (url, inHead=false, onload) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = url;
    script.async = true;

    if (typeof onload === 'function') {
      script.onload = onload
    }

    if (inHead) {
      document.head.appendChild(script)
    } else {
      document.body.appendChild(script)
    }

    return () => {
      if (inHead) {
        document.head.removeChild(script)
      } else {
        document.body.removeChild(script)
      }
    }
  }, [])
}


export default useScript