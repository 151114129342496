function getElementTop(el) {
  var elem = el
  var top = 0

  while (elem.tagName !== "BODY") {
    top += elem.offsetTop;
    elem = elem.offsetParent;
  }

  return top;

}

export { getElementTop }