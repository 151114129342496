import React from 'react'

import MainForm from '../../../../components/MainForm'

const FormSectionMobile = () => {
  return (
    <section id="form" className="px-vw-16">
      <MainForm
        title={<>Оставить <span className="text-orange">заявку</span></>}
        amoTitle="Оставить заявку"
        style={{ boxShadow: '0px 0px 50px 25px black' }}
      />
    </section>
  )
}

export default FormSectionMobile
