import React, { useContext, useEffect, useState } from 'react'

import { VideoModalContext } from '../../../../context/VideoModal'
import { ContactModalContext } from '../../../../context/ContactModal'

import Link from '../../../../components/Link'
import Sticker from '../../../../components/Sticker'

import creators from '../../../../assets/img/hero/webp/mobile/creators.webp'
import building1 from '../../../../assets/img/hero/webp/mobile/building-1.webp'
import sticker3 from '../../../../assets/img/hero/webp/mobile/sticker-3.webp'
import sticker4 from '../../../../assets/img/hero/webp/mobile/sticker-4.webp'
import folder from '../../../../assets/svg/folder.svg'
import play from '../../../../assets/svg/play-arrow.svg'

const HeroSectionMobile = ({ catalogTitle }) => {
  const { openVideo } = useContext(VideoModalContext)
  const { openModal } = useContext(ContactModalContext)
  const [windowDimensions, setWindowDimensions] = useState({
    w: window.innerWidth,
    h: window.innerHeight
  })

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth !== windowDimensions.w) {
        setWindowDimensions({
          w: window.innerWidth,
          h: window.innerHeight
        })
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section id="hero" className="flex flex-col w-full" style={{ height: windowDimensions.h, minHeight: '170vw' }}>
      <div className="flex flex-col w-full h-full">
        <div className="max-w-[80vw] pl-vw-32">
          <div className="border-l-px border-white pt-vw-20 pb-vw-3">
            <h1 className="text-mobile text-vw-xl ml-vw-14" style={{ lineHeight: '6vw', letterSpacing: '-0.1vw' }}>
              Персонализированное агентство по подбору<br/>
              <span className="text-orange">самой крутой</span> недвижимости <span className="text-orange">Москвы</span>
            </h1>
          </div>
        </div>

        <div className="flex flex-col items-end flex-grow w-full relative pt-vw-36">
          <div className="flex flex-col items-start w-[40.6vw] h-full border-l-px border-white px-vw-12 pt-vw-7">
            <h2 className="text-mobile text-vw-base mb-vw-14">{catalogTitle}</h2>

            <Link text="Скачать" icon={folder} withUnderline={true} onPress={openModal} style={{ zIndex: 60 }} />
          </div>

          <div className="w-full absolute bottom-0 pl-vw-24 pr-vw-16">
            <img
              className="w-full relative -bottom-vw-80 z-30 -mb-vw-8"
              src={creators}
              alt="создатели keepmoscow"
            />

            <Sticker
              className="absolute left-vw-7 bottom-vw-32 z-0"
              width="41vw"
              sticker={{
                src: sticker3,
                alt: 'стикер keepmoscow - based in moscow'
              }}
              startXY={[0, -50]}
              style={{
                transform: 'rotate(-13deg)'
              }}
            />
            <Sticker
              className="absolute z-10"
              width="15.6vw"
              sticker={{
                src: sticker4,
                alt: 'стикер вся правда о недвижимости'
              }}
              startXY={[25, -50]}
              style={{
                left: '42vw',
                bottom: '9vw',
                transform: 'rotate(15deg)'
              }}
            />
            <Sticker
              className="absolute left-vw-36 z-20"
              width="33vw"
              sticker={{
                src: building1,
                alt: 'здание 1'
              }}
              startXY={[0, -50]}
              style={{
                bottom: '35vw',
                transform: 'rotate(-10deg)'
              }}
            />

            <button className="flex items-center justify-center w-full absolute left-1/2 bottom-vw-28 z-50 transform -translate-x-1/2" onClick={() => openVideo('B5G77GN32MI')}>
              <img className="w-vw-24 mr-vw-20" src={play} alt="иконка о нас за 5 минут" />

              <p className="text text-vw-base">
                О нас за <span className="text-orange whitespace-nowrap relative main-underline">5 минут</span>
              </p>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroSectionMobile
