import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import xMark from '../assets/svg/x.svg'
import MainForm from '../components/MainForm'

const ContactModalContext = React.createContext()

const ContactModalProvider = ({ pdfUrl, pdfTitle, children }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100%'
    } else {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
      document.body.style.height = ''
    }
  }, [modalOpen])

  return (
    <ContactModalContext.Provider value={{ openModal: () => setModalOpen(!modalOpen) }}>
      {children}

      {modalOpen ? createPortal(
        <div className="flex justify-center items-center w-full h-full fixed inset-0 z-[100] bg-black bg-opacity-80">
          <MainForm
            title={
              <>Получить<br className="xl:inline hidden" /> <span className="xl:no-underline main-underline relative">каталог</span></>
            }
            amoTitle={`PDF: ${pdfTitle}`}
            afterSuccessUrl={pdfUrl}
          />

          <button
            className="xl:w-vw-6 xl:h-vw-6 w-vw-20 h-vw-20 absolute xl:top-vw-14 xl:right-vw-14 top-vw-24 right-[5vw]"
            onClick={() => setModalOpen(false)}
          >
            <img className="w-full filter invert" src={xMark} alt="крестик" />
          </button>
        </div>,
        document.getElementById('portal')
      ) : null}
    </ContactModalContext.Provider>
  )
}

export { ContactModalContext, ContactModalProvider }
