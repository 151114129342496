import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { API_BASE_URL } from '../../App'
import PagesLoadingWrapper from '../../components/PagesLoadingWrapper'

import logo from '../../assets/svg/logo.svg'
import { Link } from 'react-router-dom'

const FlexPage = ({ id }) => {
  const [pageState, setPageState] = useState()
  const [data, setData] = useState({})

  useEffect(() => {
    setPageState(new Promise((resolve, reject) => {
      axios.get(API_BASE_URL + `pages/${id}/`)
        .then(response => {
          setData(response.data)
          resolve()
        })
        .catch(err => reject(err))
    }))
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <PagesLoadingWrapper pageState={pageState}>
      <main>
        <header className="flex xl:justify-start justify-center xl:pt-vw-8 pt-vw-20 xl:px-vw-20">
          <Link to="/">
            <img
              className="xl:w-vw-32 w-[50vw]"
              src={logo}
              alt="логотип keepmoscow"
            />
          </Link>
        </header>

        <article className="xl:w-[75vw] w-full mx-auto xl:pt-vw-14 pt-vw-72 xl:px-0 px-vw-14">
          <h1 className="xl:text-vw-4xl text-vw-2xl font-bold text-center xl:mb-vw-14 mb-vw-56">
            { data.title }
          </h1>

          <div className="richtext" dangerouslySetInnerHTML={{
            __html: data.text
          }}/>
        </article>
      </main>
    </PagesLoadingWrapper>
  )
}

export default FlexPage
