import maksimImage from '../assets/img/about/webp/maksim.webp'
import pavelImage from '../assets/img/about/webp/pavel.webp'

const authors = {
  maksim: {
    name: 'Максим Кучеренко',
    text: (
      <>
        Для меня недвижимость — это один из самых крупных физических продуктов для продажи.
        <br />
        <br />
        Продукт, который несёт в себе не только бетон и арматуру, а эмоции людей, их будущую жизнь, уют, радость и гармонию.
        <br />
        <br />
        Мы помогаем людям найти новую среду для зачатия и свершения их прекрасных целей.
      </>
    ),
    instagramLink: 'https://instagram.com/maksooony',
    image: {
      src: maksimImage,
      alt: 'Максим Кучеренко - coосновтель агентства keepmoscow'
    }
  },
  pavel: {
    name: 'Павел Чехов',
    text: (
      <>
        Обожаю недвижимость, ее упаковку и архитектуру. Мне нравится, что мы продаём одни из самых дорогих продуктов в мире.
        <br/>
        <br/>
        Я понимаю, насколько быстро сегодня развивается отрасль девелопмента, и, при этом как сильно отстаёт брокерский рынок, что даёт нам возможность формирования новой школы брокериджа.
      </>
    ),
    instagramLink: 'https://instagram.com/chehov',
    image: {
      src: pavelImage,
      alt: 'Павел Чехов - coосновтель агентства keepmoscow'
    }
  }
}

export default authors