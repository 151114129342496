import React, { useContext } from 'react'
import { animated as a } from 'react-spring'

import { VideoModalContext } from '../../../../context/VideoModal'
import { ContactModalContext } from '../../../../context/ContactModal'
import { BreakpointsContext } from '../../../../context/breakpoints'

import Link from '../../../../components/Link'
import MainForm from '../../../../components/MainForm'
import VideoPreview from '../../../../components/VideoPreview'

import keepmoscowVideo from '../../../../assets/img/contact/webp/desktop/kpmscw-video.webp'
import folder from '../../../../assets/svg/folder.svg'

import useInterpConfig from '../../../../hooks/interp'

const ContactSection = ({ spring: { st } }) => {
  const { b, setBrRef } = useContext(BreakpointsContext)
  const [interpConfig] = useInterpConfig()
  const { openVideo } = useContext(VideoModalContext)
  const { openModal } = useContext(ContactModalContext)

  return (
    <section ref={ref => setBrRef('contact', ref)} id="contact" className="flex flex-col w-full px-vw-12 pt-vw-28 pb-vw-20">
      <div className="flex items-stretch w-full h-full mb-vw-24">
        <div className="flex flex-col justify-end items-start w-[15.2vw] px-vw-10">
          <p className="text mb-vw-6">
            ТОП-31<br />комплексов Москвы по<br />версии Keepmoscow
          </p>

          <Link
            text="Скачать"
            withUnderline={true}
            icon={folder}
            style={{ zIndex: 1 }}
            onPress={openModal}
          />
        </div>

        <a.div className="flex flex-col justify-between w-[29.2vw] px-vw-10" style={{
          translateX: st.to(interpConfig([
            [b.get('contact', '-60%'), 14.7],
            [b.get('contact', '-20%'), 0]
          ])).to(x => `${x}vw`)
        }}>
          <p className="text text-vw-3xl mt-vw-10">
            Персонализированное агентство по подбору{' '}
            <span className="text-orange">самой крутой</span>
            {' недвижимости '}<br />
            <span className="text-orange">Москвы</span>
          </p>

          <div className="flex flex-col items-start">
            <Link
              href="mailto:info@keepmoscow.ru"
              className="mb-vw-2"
              text={<p>info<span className="text-orange">@</span>keepmoscow.ru</p>}
            />
            <Link
              href="tel:+7-499-302-21-06"
              text="+7 (499) 302-21-06"
            />
          </div>
        </a.div>

        <a.div className="flex flex-col justify-center items-center flex-grow px-vw-10" style={{
          translateX: st.to(interpConfig([
            [b.get('contact', '-60%'), 20.3],
            [b.get('contact', '-20%'), 0]
          ])).to(x => `${x}vw`)
        }}>
          <VideoPreview
            poster={{
              src: keepmoscowVideo,
              alt: 'keepmoscow video poster'
            }}
            onClick={() => openVideo('B5G77GN32MI')}
          />

          <p className="text mt-vw-5">
            О нас за <span className="text-orange relative main-underline">5 минут</span>
          </p>
        </a.div>
      </div>

      <MainForm
        title={<>Оставить<br />заявку</>}
        amoTitle="Оставить заявку"
        className="mx-vw-10"
        withLines={false}
        style={{ boxShadow: '0px 0px 20px 45px #080B0F' }}
      />
    </section>
  )
}

export default ContactSection
