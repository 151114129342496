import React, { useContext } from 'react'
import { animated as a } from 'react-spring'

import { VideoModalProvider } from '../../../context/VideoModal'
import { BreakpointsContext } from '../../../context/breakpoints'

import HeroSection from './sections/HeroSection'
import ApproachSection from './sections/ApproachSection'
import FormSection from './sections/FormSection'
import AboutUsSection from './sections/AboutUsSection'
import TeamSection from './sections/TeamSection'
import ContactSection from './sections/ContactSection'

import useInterpConfig from '../../../hooks/interp'

const HomePage = ({ data, spring: { st }}) => {
  const { team } = data
  const { b } = useContext(BreakpointsContext)
  const [interpConfig] = useInterpConfig()

  return (
    <VideoModalProvider>
      <HeroSection spring={{ st }} catalogTitle={data.catalog_title} />
      <ApproachSection spring={{ st }} />
      <FormSection />
      <AboutUsSection spring={{ st }} />
      {team && (
        <TeamSection spring={{ st }} team={team} />
      )}
      <ContactSection spring={{ st }} />

      <div className="w-screen fixed inset-0 z-[-10] overflow-hidden">
        <a.div className="w-px bg-white absolute top-vw-7 bottom-vw-7" style={{ 
          left: '3.75vw',
          translateX: st.to(interpConfig(
            [
              [b.get('approach2'), 0],
              [b.get('approach3'), -16.3],
              [b.get('approach4'), 0],
            ]
          )).to(l => `${l}vw`)
        }} />

        <a.div className="w-px bg-white absolute top-vw-7 bottom-vw-7" style={{
          left: '3.75vw',
          translateX: st.to(interpConfig(
            [
              [b.get('approach2'), 16.3 ],
              [b.get('approach3'), -44.3 ],
            ]
          )).to(l => `${l}vw`)
        }} />

        <a.div className="w-px bg-white absolute top-vw-7 bottom-vw-7" style={{
          left: '3.75vw',
          translateX: st.to(interpConfig(
            [
              [b.get('approach2'), 44.3 ],
              [b.get('approach3'), 0 ],
              [b.get('approach3', '40%'), 0 ],
              [b.get('approach4'), 23 ],

              [b.get('form'), 23 ],
              [b.get('about1', '-30%'), 16.3 ],
              [b.get('about2', '-40%'), 16.3 ],
              [b.get('about2', '-10%'), 29.8 ],
              [b.get('contact', '-60%'), 29.8 ],
              [b.get('contact', '-20%'), 15.2 ],
            ]
          )).to(l => `${l}vw`)
        }} />

        <a.div className="w-px bg-white absolute top-vw-7 bottom-vw-7" style={{
          right: '3.75vw',
          translateX: st.to(interpConfig(
            [
              [b.get('approach2'), 0 ],
              [b.get('approach3'), -42.75 ],
              [b.get('approach3', '40%'), -42.75 ],
              [b.get('approach4'), 0 ],
              [b.get('approach5'), 0 ],
              [b.get('approach6', '-20%'), -34.5 ],

              [b.get('form'), -34.5 ],
              [b.get('about1', '-30%'), -16.1 ],
              [b.get('team1'), -16.1 ],
              [b.get('team1', '30%'), -27.8 ],
              [b.get('contact', '-60%'), -27.8 ],
              [b.get('contact', '-20%'), -48.1 ],
            ]
          )).to(l => `${l}vw`)
        }} />

        <a.div className="w-px bg-white absolute top-vw-7 bottom-vw-7" style={{
          right: '3.75vw',
          translateX: st.to(interpConfig(
            [
              [b.get('approach2'), 20],
              [b.get('approach3'), 0],
              [b.get('approach3', '40%'), 0],
              [b.get('approach4'), 20],
              [b.get('approach5'), 20],
              [b.get('approach6', '-20%'), 0],
            ]
          )).to(l => `${l}vw`)
        }} />
      </div>
    </VideoModalProvider>
  )
}

export default HomePage
