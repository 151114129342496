import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useSpring } from 'react-spring'
import useScrollPosition from '@react-hook/window-scroll'
import useScript from './utils/scripts';

import { YMInitializer } from 'react-yandex-metrika';

import MainPage from './pages/MainPage'
import FlexPage from './pages/FlexPage'
import BotPage from './pages/BotPage'

export const API_BASE_URL = '/api/v2/'


const App = () => {
  const location = useLocation()
  const [flexPages, setFlexPages] = useState([])
  const [homePages, setHomePages] = useState([])

  const [spring, api] = useSpring(() => ({ st: 0 }))

  const scrollY = useScrollPosition(45)
  useEffect(() => {
    api.start({ st: scrollY })
  }, [scrollY, api])

  useEffect(() => {
    const ReactPixel = require('react-facebook-pixel');
    ReactPixel.default.init('924905022184910');
    ReactPixel.default.pageView();

    axios.get(API_BASE_URL + 'pages/?type=flex.FlexPage')
      .then(response => {
        const data = response.data
        const pages = data.items
        setFlexPages(pages)
      })
      .catch(err => console.warn(err))

    axios.get(API_BASE_URL + 'pages/?type=home.HomePage')
      .then(response => {
        const data = response.data
        const pages = data.items.filter(page => page.id !== 3)
        setHomePages(pages)
      })
      .catch(err => console.warn(err))
  }, [])

  useScript('https://vk.com/js/api/openapi.js?169', true, () => {
    VK.Retargeting.Init('VK-RTRG-1226713-5PugF');  // eslint-disable-line
    VK.Retargeting.Hit();  // eslint-disable-line
  })

  return (
    <>
      <YMInitializer
        accounts={[86636899]}
        options={{ webvisor: true, clickmap: true, trackLinks: true }}
        version="2"
      />

      <Routes location={location}>
        {flexPages.map(page => (
          <Route
            key={page.id}
            path={`/${page.meta.slug}`}
            exact
            element={
              <FlexPage id={page.id} />
            }
          />
        ))}

        {homePages.map(page => (
          <Route
            key={page.id}
            path={`/${page.meta.slug}`}
            exact
            element={
              <MainPage id={page.id} spring={spring} />
            }
          />
        ))}
        <Route path="/" exact element={
          <MainPage id={3} spring={spring} />
        } />
        <Route path="/bot" exact element={
          <BotPage />
        } />
      </Routes>
    </>
  )
}


export default App;
