import React, { useState } from 'react'

const VideoPreview = ({ poster: { src, alt }, onClick, ...props }) => {
  const defaultFill = 'rgba(255, 255, 255, 0.4)'
  const [fill, setFill] = useState(defaultFill)

  return (
    <div className="w-full aspect-w-16 aspect-h-9" {...props}>
      <div
        className="group w-full h-full cursor-pointer"
        onPointerOver={() => setFill('#EB682A')}
        onPointerOut={() => setFill(defaultFill)}
        onClick={onClick}
      >
        <img className="w-full h-full object-cover object-center" src={src} alt={alt} />

        <svg className="xl:w-[4.1vw] w-vw-28 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-transform" viewBox="0 0 78 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className="transition-colors" fill={fill} d="M75.8137 8.78556C74.9278 5.49219 72.3312 2.89606 69.0383 2.00926C63.0226 0.363281 38.9593 0.363281 38.9593 0.363281C38.9593 0.363281 14.8969 0.363281 8.88117 1.94686C5.65161 2.83272 2.99168 5.49266 2.10581 8.78556C0.522705 14.8008 0.522705 27.2756 0.522705 27.2756C0.522705 27.2756 0.522705 39.8133 2.10581 45.7657C2.99261 49.0586 5.58827 51.6547 8.88164 52.5415C14.9602 54.188 38.9602 54.188 38.9602 54.188C38.9602 54.188 63.0226 54.188 69.0383 52.6044C72.3317 51.7181 74.9278 49.1219 75.8146 45.829C77.3972 39.8133 77.3972 27.339 77.3972 27.339C77.3972 27.339 77.4606 14.8008 75.8137 8.78556ZM31.298 38.8003V15.7509L51.3079 27.2756L31.298 38.8003Z" />
        </svg>
      </div>
    </div>
  )
}

export default VideoPreview
