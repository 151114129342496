import React from 'react'
import { Link } from 'react-router-dom'

import evgmedLogo from '../assets/svg/evgmed-white.svg'

const FooterLink = ({ routerLink=false, href, text, ...props }) => {
  return routerLink ? (
    <Link
      to={href}
      className="text text-vw-lg font-extralight uppercase
        text-right text-white
        relative main-underline
        p-vw-3 mx-vw-6"
    >
      {text}
    </Link>
  ) : (
    <a
      className="text text-vw-lg font-extralight uppercase
        text-right text-white
        relative main-underline
        p-vw-3 mx-vw-6"
      href={href}
      {...props}
    >
      {text}
    </a>
  )
}

const FooterMobile = () => {
  return (
    <footer className="flex flex-col w-full pt-vw-64 pb-vw-48">
      <div className="w-full pr-vw-44">
        <div className="flex flex-col items-end border-r-px border-white pr-vw-3">
          {/* <FooterLink href="https://www.instagram.com/keep.moscow/" text="INSTAGRAM" style={{ marginBottom: '8vw' }} /> */}
          <FooterLink href="mailto:info@keepmoscow.ru" text="info@keepmoscow.ru" style={{ marginBottom: '8vw' }} />
          <FooterLink routerLink={true} href="/privacy-policy" text={<>ПОЛИТИКА<br/>КОНФИДЕНЦИАЛЬНОСТИ</>} />
        </div>
      </div>

      <div className="pr-vw-44">
        <div className="flex justify-end border-r-px border-white pt-vw-72">
          <a
            href="https://evgmed.com/?utm_source=kpmscw&utm_medium=site&utm_campaign=UTM+keepmoscow"
            className="flex flex-col items-end opacity-50 active:opacity-100 mr-vw-12"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-vw-xs font-extralight mb-vw-2">CREATED BY</p>
            <img className="w-[28vw]" src={evgmedLogo} alt="логотип evgmed" />
          </a>
        </div>
      </div>

      <div className="mt-vw-56 pr-vw-44">
        <p className="text text-vw-base font-extralight text-right">
          {new Date().getFullYear()} © KEEPMOSCOW
        </p>
      </div>
    </footer>
  )
}

export default FooterMobile
