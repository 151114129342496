import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'

import { ContactModalProvider } from '../../context/ContactModal'
import { BreakpointsProvider } from '../../context/breakpoints'

import { API_BASE_URL } from '../../App'

import PagesLoadingWrapper from '../../components/PagesLoadingWrapper'
import Header from '../../partials/Header'
import Footer from '../../partials/Footer'
import FooterMobile from '../../partials/FooterMobile'
import HomePage from './HomePage'
import HomePageMobile from './HomePageMobile'

import creators from '../../assets/img/hero/webp/desktop/creators.webp'
import sticker1 from '../../assets/img/hero/webp/desktop/sticker-1.webp'
import sticker2 from '../../assets/img/hero/webp/desktop/sticker-2.webp'
import sticker3 from '../../assets/img/hero/webp/desktop/sticker-3.webp'
import sticker4 from '../../assets/img/hero/webp/desktop/sticker-4.webp'
import building1 from '../../assets/img/hero/webp/desktop/building-1.webp'

import creators_m from '../../assets/img/hero/webp/mobile/creators.webp'
import building1_m from '../../assets/img/hero/webp/mobile/building-1.webp'
import sticker3_m from '../../assets/img/hero/webp/mobile/sticker-3.webp'
import sticker4_m from '../../assets/img/hero/webp/mobile/sticker-4.webp'

const desktopImages = [creators, sticker1, sticker2, sticker3, sticker4, building1]
const mobileImages = [creators_m, building1_m, sticker3_m, sticker4_m]

const MainPage = ({ id, spring }) => {
  const [pageState, setPageState] = useState()
  const [data, setData] = useState({})
  const isDesktop = useMediaQuery({ query: '(min-width: 640px)' })
  const [pdf, setPdf] = useState({})

  useEffect(() => {
    const dataPromise = new Promise((resolve, reject) => {
      axios.get(API_BASE_URL + `pages/${id}/`)
        .then(response => {
          setData(response.data)
          setPdf(response.data.catalog_file)
          resolve()
        })
        .catch(err => reject(err))
    })

    const imagePromises = (isDesktop ? desktopImages : mobileImages).map(src => {
      return new Promise((resolve) => {
        const img = new Image()
        img.src = src
        img.onload = resolve()
      })
    })

    setPageState(Promise.all([dataPromise, imagePromises]))
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContactModalProvider pdfUrl={pdf.url} pdfTitle={pdf.title}>
      <PagesLoadingWrapper pageState={pageState}>
        {isDesktop ? (

          <BreakpointsProvider>
            <Header spring={spring} />
            <HomePage data={data} spring={spring} />
            <Footer />
          </BreakpointsProvider>

        ) : (

          <>
            <HomePageMobile data={data} spring={spring} />
            <FooterMobile />
          </>

      )}
    </PagesLoadingWrapper>
  </ContactModalProvider>
  )
}

export default MainPage
