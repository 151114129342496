import React, { useEffect, useRef, useState } from 'react'
import { animated as a } from "react-spring";

import { getElementTop } from '../utils/elems'

const SlidingTitle = ({ text, spring: { st } }) => {
  const [offsetHeight, setOffsetHeight] = useState(0)
  const [offsetTop, setOffsetTop] = useState(0)
  // const [sw, setSw] = useState(0)
  const textRef = useRef()

  // useEffect(() => {
  //   const setSwNow = () => {
  //     !textRef.current
  //       ? setTimeout(setSwNow, 100)
  //       : setSw(textRef.current?.offsetWidth)
  //   }
  //   setSwNow()

  //   window.addEventListener('resize', setSwNow)

  //   return () => window.removeEventListener('resize', setSwNow)
  // }, [textRef])

  useEffect(() => {
    if (textRef.current) {
      setOffsetTop(getElementTop(textRef.current))
      setOffsetHeight(textRef.current.offsetHeight)
    }
  }, [textRef])

  return (
    <div ref={textRef} className="w-full overflow-hidden">
      <a.h3 className="text-[32vw] leading-[39vw] whitespace-nowrap" style={{
        translateX: st.to({
          range: [offsetTop - window.innerHeight + offsetHeight + 150, offsetTop + offsetHeight],
          output: [window.innerWidth, -700],
        })
      }}>
        {/* <span ref={textRef}>
          {text}&nbsp;&nbsp;&nbsp;
        </span> */}
        
        {text}&nbsp;&nbsp;&nbsp;{text}&nbsp;&nbsp;&nbsp;{text}
      </a.h3>
    </div>
  )
}

export default SlidingTitle
