import React from 'react'

import CreatorCard from '../../../../components/CreatorCard'
import SlidingTitle from '../../../../components/SlidingTitle'

import authors from '../../../../data/authors'

import helmet from '../../../../assets/img/about/webp/mobile/helmet.webp'
import sticker from '../../../../assets/img/about/webp/mobile/sticker-6.webp'
import Sticker from '../../../../components/Sticker'

const AboutUsSectionMobile = ({ spring }) => {
  return (
    <section id="abous-us" className="flex flex-col mt-vw-28">
      <SlidingTitle text="КТО МЫ?" spring={spring} />

      <div className="flex justify-end w-full pr-vw-44 -mt-vw-4">
        <div className="flex border-r-px border-white pt-vw-48 pb-vw-14 pr-vw-16">
          <h4 className="text-vw-lg text-right">
            Мы - свои люди в<br />недвижимости
          </h4>
        </div>
      </div>

      <div className="w-full px-vw-10 mt-vw-24">
        <CreatorCard {...authors.maksim} className="mb-vw-10" />
        <CreatorCard {...authors.pavel} />
      </div>

      <div className="flex items-stretch w-full mt-vw-44">
        <div className="flex-shrink-0 w-[31vw] border-r-px border-white pb-[36vw]">
          <img className="w-full" src={helmet} alt="каска keepmoscow" />
          <Sticker
            className="-mt-vw-12 ml-vw-12"
            sticker={{
              src: sticker,
              alt: 'стикер 6'
            }}
            width="62vw"
          />
        </div>

        <div className="flex flex-col flex-grow justify-between pt-vw-44 pb-vw-32 pl-vw-32">
          <h4 className="text-vw-xl">
            Работаем<br /><span className="text-orange">со всеми<br />застройщиками</span><br />в Москве
          </h4>
          <h4 className="text-vw-xl">
            Поэтому знаем<br />
            о всех стартах продаж и <span className="text-orange">максимальных скидках</span>
          </h4>
        </div>
      </div>
    </section>
  )
}

export default AboutUsSectionMobile
