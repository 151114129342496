import React, { useContext } from 'react'

import { BreakpointsContext } from '../../../../context/breakpoints'

import MainForm from '../../../../components/MainForm'

const ApplicationFormSection = () => {
  const { setBrRef } = useContext(BreakpointsContext)

  return (
    <section ref={ref => setBrRef('form', ref)} id="form" className="w-full px-vw-24 pt-vw-32 pb-vw-14">
      <MainForm
        title={<>Оставить<br /> <span>заявку</span></>}
        amoTitle="Оставить заявку"
        style={{ boxShadow: '0px 0px 20px 45px #080B0F' }}
      />
    </section>
  )
}

export default ApplicationFormSection
