import React, { useRef } from 'react'

import { getElementTop } from '../utils/elems'

class Breakpoints {
  constructor() {
    this._bs = {}
  }

  init(refs) {
    for (const [name, ref] of Object.entries(refs)) {
      if (name && ref) {
        this._bs[name] = {
          top: getElementTop(ref),
          height: ref.offsetHeight
        }
      }
    }
  }

  get(name, offset) {
    if (name in this._bs) {
      let brkpnt = this._bs[name]

      if (typeof offset === 'number') {
        return brkpnt.top - offset
      } else if (typeof offset === 'string') {
        if (offset.includes('%')) {
          let percent = Number(offset.replace('%', ''))
          let broffset = (brkpnt.height * percent) / 100
          return brkpnt.top + broffset
        }
      } else {
        return brkpnt.top
      }
    }

    return null
  }

  _getAll() {
    return this._bs
  }
}

const b = new Breakpoints()
const BreakpointsContext = React.createContext()

const BreakpointsProvider = ({ children }) => {
  const brRefs = useRef({})

  const setBrRef = (name, ref) => {
    brRefs.current[name] = ref
    b.init(brRefs.current)
  }

  return (
    <BreakpointsContext.Provider value={{ b, setBrRef }}>
      {children}
    </BreakpointsContext.Provider>
  )
}

export { BreakpointsProvider, BreakpointsContext }
