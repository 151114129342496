const useInterpConfig = () => {
  const interpConfig = (bno) => {
    var breaks = []
    var outputs = []

    bno.map(bo => {
      breaks.push(bo[0])
      outputs.push(bo[1])

      return null
    })

    return {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp',
      range: breaks,
      output: outputs
    }
  }

  return [interpConfig]
}

export default useInterpConfig