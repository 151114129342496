import React, { useEffect, useRef, useState } from 'react'
import { animated as a } from "react-spring";

import Sticker from '../../../../components/Sticker';
import SlidingTitle from '../../../../components/SlidingTitle'

import approach0 from '../../../../assets/img/approach/webp/mobile/approach0.webp'
import approach1 from '../../../../assets/img/approach/webp/mobile/approach1.webp'
import approach3 from '../../../../assets/img/approach/webp/mobile/approach3.webp'
import approach4 from '../../../../assets/img/approach/webp/mobile/approach4.webp'
import approach5 from '../../../../assets/img/approach/webp/mobile/approach5.webp'
import approach6 from '../../../../assets/img/approach/webp/mobile/approach6.webp'
import sticker1 from '../../../../assets/img/approach/webp/mobile/sticker-3.webp'
import sticker2 from '../../../../assets/img/approach/webp/mobile/sticker-2.webp'
import building1 from '../../../../assets/img/approach/webp/mobile/building-2.webp'
import email from '../../../../assets/svg/email-sent.svg'
import desktop from '../../../../assets/svg/desktop-mobile.svg'

import useInterpConfig from '../../../../hooks/interp';
import { getElementTop } from '../../../../utils/elems';

const objects = [
  { image: { src: approach0, alt: 'изображение объекта' } },
  { image: { src: approach1, alt: 'изображение объекта' } },
  { image: { src: approach6, alt: 'изображение объекта' } },
  { image: { src: approach3, alt: 'изображение объекта' } },
  { image: { src: approach4, alt: 'изображение объекта' } },
]

const ApproachSectionMobile = ({ spring: { st } }) => {
  const objectsBox1 = useRef(), objectsBox2 = useRef()
  const sectionRef = useRef()
  const [offsetTop, setOffsetTop] = useState(0)
  const [objectsY, setObjectsY] = useState(0)
  const [interpConfig] = useInterpConfig()

  useEffect(() => {
    setObjectsY(
      objectsBox2.current?.offsetTop
      - objectsBox1.current?.offsetTop
    )
  }, [objectsBox1, objectsBox2])

  useEffect(() => {
    if (sectionRef.current) {
      setOffsetTop(getElementTop(sectionRef.current))
    }
  }, [sectionRef])

  const imgInterpY = st.to(interpConfig([
    [offsetTop + 500, 0],
    [offsetTop + 900, -50]
  ]))
  const imgInterpOpacity = st.to(interpConfig([
    [offsetTop + 500, 1],
    [offsetTop + 900, 0]
  ]))

  return (
    <section ref={sectionRef} id="approach" className="flex flex-col w-full pt-vw-20">
      <SlidingTitle text="НАШ ПОДХОД" spring={{ st }} />

      <div className="flex flex-col w-full overflow-hidden pl-vw-32 mt-vw-20">
        <div className="flex flex-col items-start w-full border-l-px border-white pt-vw-7 pb-vw-24 pl-vw-24">
          <h4 className="text text-vw-xl normal-case tracking-tight">
            В начале мы проводим интервью и определяем то,<br/>
            что вы хотите<br/>
            <span className="text-orange">на самом деле</span>
          </h4>

          <div className="flex flex-col items-start w-full mt-vw-44">
            <div ref={objectsBox1} className="flex w-full h-[39vw] relative">
              {objects.map((obj, idx) => (
                <a.img
                  key={idx}
                  className="h-full absolute top-0 left-0 bottom-0"
                  src={obj.image.src}
                  alt={obj.image.alt}
                  style={{
                    translateX: `${ idx * 15 }vw`,
                    translateY: [0, 2, 4].includes(idx)
                      ? st.to(interpConfig([
                        [offsetTop - 300 + (idx * 50), 0],
                        [offsetTop + 100 + (idx * 50), objectsY]
                      ]))
                      : 0
                  }}
                />
              ))}
            </div>

            <div className="pr-vw-24 mt-vw-68 mb-vw-56">
              <h4 className="text-vw-xl">
                Подбираем <span className="text-orange">3-5 лучших </span>
                вариантов на основе интервью и ваших запросов
              </h4>
            </div>

            <div ref={objectsBox2} className="flex w-full h-[39vw] relative">
            </div>
          </div>
        </div>
      </div>

      <div className="flex mt-vw-44 pr-vw-44">
        <div className="flex flex-col w-full border-r-px border-white pt-vw-16 pb-vw-28 pr-vw-20">
          <h4 className="ml-vw-28 text text-vw-xl normal-case">
            Оформляем<br />в персональную<br />презентацию
          </h4>

          <div className="w-full relative mt-vw-32">
            <img
              className="w-full"
              src={desktop}
              alt="изображение экрана"
            />

            <div className="h-full absolute left-0 right-0 bottom-vw-1 overflow-hidden">
              <a.img
                className="w-5/6 h-[33vw] absolute left-1/2 bottom-vw-56 object-cover"
                src={approach5}
                alt={'изображение объекта'}
                style={{
                  opacity: imgInterpOpacity,
                  translateY: imgInterpY,
                  translateX: '-50%'
                }}
              />
              <a.img
                className="w-5/6 h-[33vw] absolute left-1/2 bottom-vw-28 object-cover"
                src={objects[0].image.src}
                alt={objects[0].image.alt}
                style={{
                  opacity: imgInterpOpacity,
                  translateY: imgInterpY,
                  translateX: '-50%'
                }}
              />
              <a.img
                className="w-5/6 h-[33vw] absolute left-1/2 bottom-0 object-cover"
                src={objects[3].image.src}
                alt={objects[3].image.alt}
                style={{
                  opacity: imgInterpOpacity,
                  translateY: imgInterpY,
                  translateX: '-50%'
                }}
              />

              <a.div className="flex flex-col justify-center items-center w-full h-full" style={{
                opacity: st.to(interpConfig([
                  [offsetTop + 500, 0],
                  [offsetTop + 900, 1]
                ])),
                translateY: st.to(interpConfig([
                  [offsetTop + 500, 50],
                  [offsetTop + 900, 0]
                ])),
              }}>
                <img className="w-vw-28 mb-vw-10" src={email} alt="изображение письмо отправлено" />
                <p className="text-vw-lg">И отправляем вам</p>
              </a.div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full relative mt-vw-52 pt-vw-20 pb-vw-72">
        <div className="w-1/2 pr-vw-28">
          <h4 className="text-vw-lg text-right">
            Для вас это бесплатно
          </h4>
        </div>

        <div className="flex justify-center w-full mt-vw-10">
          <Sticker
            sticker={{
              src: sticker1,
              alt: 'стикер диск keepmoscow'
            }}
            width="52vw"
          />
        </div>

        <div className="flex w-1/2 pl-vw-28 mt-vw-14 ml-auto">
          <h4 className="text-vw-lg">
            Комиссию<br />
            нам платит<br />
            <span className="text-orange">застройщик</span>
          </h4>
        </div>

        <div className="flex justify-center w-full relative mt-vw-40">
          <Sticker
            sticker={{
              src: building1,
              alt: 'здание 1'
            }}
            width="60vw"
          />

          <Sticker
            className="absolute left-vw-72 bottom-vw-48 transform"
            sticker={{
              src: sticker2,
              alt: 'стикер my name is keepmoscow'
            }}
            width="46vw"
          />
        </div>

        <div className="w-1/2 pr-vw-20 mt-vw-32">
          <h4 className="text-vw-lg text-right">
            Подбираем<br />
            недвижимость<br />
            <span className="text-orange">из лучшего</span>,<br />
            что предлагает<br />
            Московский рынок
          </h4>
        </div>

        <div
          className="w-px h-full absolute top-0 left-1/2 bottom-0 z-[-1] bg-white
            transform -translate-x-1/2"
        ></div>
      </div>
    </section>
  )
}

export default ApproachSectionMobile
