import React, { useState, useEffect, useCallback } from 'react'
import { useSpring, useTransition, animated as a } from 'react-spring'
import { useMediaQuery } from 'react-responsive'

import AnimatedLogo from '../components/AnimatedLogo'

const PagesLoadingWrapper = ({ pageState, children }) => {
  const [animationReady, setAnimationReady] = useState(false)
  const [ready, setReady] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const isDesktop = useMediaQuery({ query: '(min-width: 1280px)' })

  const [spring, api] = useSpring(() => ({ xy: [0, 0] }))
  const transition = useTransition(ready, {
    enter: { opacity: 1, translateY: 0 },
    leave: { opacity: 0, translateY: -10 },
  })
  const screenTransition = useTransition(ready, {
    from: { opacity: 0, translateY: 10 },
    enter: { opacity: 1, translateY: 0 },
  })

  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => api.start({
    xy: [x, y]
  }), [])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof pageState !== "undefined") {
      pageState
        .then(() => {
          if (animationReady) {
            setTimeout(() => setReady(true), 300)
          }
        })
        .catch(() => setLoadingText('Ошибка!'))
    }
  }, [pageState, animationReady])

  return (
    <>
      {transition((styles, rdy) =>
      !rdy && (
        <a.div
          className="flex justify-center items-center w-full h-full fixed inset-0 bg-gray-dark"
          style={{
            opacity: styles.opacity.to(o => !ready ? 1 : o),
            zIndex: 200
          }}
          {...((isDesktop && !ready) ? {
            onMouseMove: onMouseMove
          } : {})}
        >
          <a.div className="w-2/3" style={{
            translateY: styles.translateY.to(y => !ready ? 0 : `${y * 3}vw`)
          }}>
            <AnimatedLogo handleAnimationEnd={() => setAnimationReady(true)} />

            {isDesktop && (
              <a.div className="absolute top-0 left-0 opacity-20" style={{
                transform: spring.xy.to((x, y) => `translate(${x - 15}px, ${y + 30}px)`)
              }}>
                <p className="text text-vw-sm font-extralight">
                  {loadingText}
                </p>
              </a.div>
            )}
          </a.div>
        </a.div>
      ))}
      
      {screenTransition((styles, rdy) => (
        <a.div style={styles}>
          {children}
        </a.div>
      ))}
    </>
  )
}

export default PagesLoadingWrapper
