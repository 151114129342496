import React from 'react'

const AnimatedDownArrow = () => {
  return (
    <svg className="w-vw-3 overflow-visible" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="animate-bounce" d="M1.77 23L0 24.77L10 34.77L20 24.77L18.23 23L10 31.23L1.77 23Z" fill="#EB682A" style={{ animation: 'bounce 1s 100ms infinite' }} />
      <path className="animate-bounce" d="M1.77 12L0 13.77L10 23.77L20 13.77L18.23 12L10 20.23L1.77 12Z" fill="#EB682A" style={{ animation: 'bounce 1s 200ms infinite' }} />
      <path className="animate-bounce" d="M1.77 0L0 1.77L10 11.77L20 1.77L18.23 0L10 8.23L1.77 0Z" fill="#EB682A" style={{ animation: 'bounce 1s 300ms infinite' }} />
    </svg>
  )
}

export default AnimatedDownArrow
