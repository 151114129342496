import React, { useContext } from 'react'
import { animated as a } from 'react-spring'

import { VideoModalContext } from '../../../../context/VideoModal'
import { ContactModalContext } from '../../../../context/ContactModal'
import { BreakpointsContext } from '../../../../context/breakpoints'

import Link from '../../../../components/Link'
import Sticker from '../../../../components/Sticker'
import AnimatedDownArrow from '../../../../components/AnimatedDownArrow'

import creators from '../../../../assets/img/hero/webp/desktop/creators.webp'
import sticker1 from '../../../../assets/img/hero/webp/desktop/sticker-1.webp'
import sticker2 from '../../../../assets/img/hero/webp/desktop/sticker-2.webp'
import sticker3 from '../../../../assets/img/hero/webp/desktop/sticker-3.webp'
import sticker4 from '../../../../assets/img/hero/webp/desktop/sticker-4.webp'
import building2 from '../../../../assets/img/hero/webp/desktop/building-1.webp'
import folder from '../../../../assets/svg/folder.svg'
import play from '../../../../assets/svg/play-arrow.svg'

import useInterpConfig from '../../../../hooks/interp'

const HeroSection = ({ spring: { st }, catalogTitle }) => {
  const [interpConfig] = useInterpConfig()
  const { b, setBrRef } = useContext(BreakpointsContext)
  const { openVideo } = useContext(VideoModalContext)
  const { openModal } = useContext(ContactModalContext)

  return (
    <a.section ref={ref => setBrRef('hero', ref)} id="hero" className="flex h-screen px-vw-12 py-vw-7" style={{
      opacity: st.to(interpConfig([
        [b.get('hero'), 1],
        [b.get('approach1', '-30%'), 0]
      ])),
      translateY: st.to(interpConfig([
        [b.get('hero'), 0],
        [b.get('approach1', '-30%'), 100]
      ]))
    }}>
      <div className="flex flex-col flex-shrink-0 w-[16.25vw] px-vw-7">
        <div className="flex flex-col flex-grow justify-end items-start">
          <h2 className="text mb-vw-6">{catalogTitle}</h2>

          <Link text="Скачать" withUnderline={true} icon={folder} style={{ zIndex: 1 }} onClick={openModal} />
        </div>

        <div className="flex flex-col mt-vw-36">
          <p className="text mb-vw-3 animate-bounce delay-500">СКРОЛЛЬ</p>
          <AnimatedDownArrow />
        </div>
      </div>

      <div className="flex flex-col flex-shrink-0 w-[28.2vw] h-full px-vw-10">
        <h1 className="text text-vw-3xl mt-vw-28">
          Персонализированное агентство по подбору
          <span className="text-orange"> самой крутой</span> недвижимости
          <span className="text-orange"> Москвы</span>
        </h1>

        <div className="flex flex-grow relative">
          <Sticker
            className="absolute left-vw-4 bottom-0 z-20"
            width="13.75vw"
            sticker={{
              src: sticker1,
              alt: "стикер no f#cking realtors"
            }}
            startXY={[-10, -50]}
          />
          <Sticker
            className="absolute -left-vw-28 -bottom-vw-9 z-10"
            width="16.5vw"
            sticker={{
              src: building2,
              alt: "cтикер здания 1"
            }}
            startXY={[-10, -50]}
            style={{
              transform: 'rotate(-19deg)'
            }}
          />
          <Sticker
            className="absolute left-vw-24 -bottom-vw-9 z-0"
            width="11vw"
            sticker={{
              src: sticker2,
              alt: "стикер 2"
            }}
            startXY={[10, -50]}
            style={{
              transform: 'rotate(10deg)'
            }}
          />
        </div>
      </div>

      <div className="flex flex-grow items-end relative px-vw-4">
        <Sticker
          className="absolute -left-vw-4 -bottom-vw-2"
          width="23.4vw"
          sticker={{
            src: sticker3,
            alt: 'стикер keepmoscow - based in moscow'
          }}
          startXY={[-20, -50]}
          style={{
            transform: 'rotate(-12deg)'
          }}
        />
        <Sticker
          className="absolute bottom-vw-9"
          width="7vw"
          sticker={{
            src: sticker4,
            alt: 'стикер вся правда о недвижимости'
          }}
          startXY={[20, -50]}
          style={{
            left: '21vw',
            transform: 'rotate(2deg)'
          }}
        />

        <img
          className="w-[46.5vw] max-w-none h-auto relative -bottom-vw-48 z-10 object-contain -ml-vw-2"
          src={creators}
          alt="Создатели Keepmoscow"
        />

        <button className="flex items-center absolute left-1/2 bottom-vw-20 z-30 transform -translate-x-1/2" onClick={() => openVideo('B5G77GN32MI')}>
          <img className="w-vw-7 mr-vw-4" src={play} alt="иконка о нас за 5 минут" />
          <p className="text text-vw-xl">
            О нас за <span className="text-orange relative main-underline">5 минут</span>
          </p>
        </button>
      </div>
    </a.section>
  )
}

export default HeroSection
