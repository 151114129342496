import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import xMark from '../assets/svg/x.svg'

const VideoModalContext = React.createContext()

const VideoModalProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');

  const openVideo = (vidId) => {
    setVideoId(vidId)
    setModalOpen(true)
  }

  useEffect(() => {
    if (modalOpen) {
      document.documentElement.style.overflow = 'hidden'
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100%'
    } else {
      document.documentElement.style.overflow = ''
      document.body.style.overflow = ''
      document.body.style.height = ''
    }
  }, [modalOpen])

  return (
    <VideoModalContext.Provider value={{ openVideo }}>
      { children }

      <VideoModal
        isOpen={modalOpen}
        videoId={videoId}
        handleClose={() => setModalOpen(false)}
      />
    </VideoModalContext.Provider>
  )
}

const VideoModal = ({ isOpen, videoId, handleClose }) => {
  if (isOpen && videoId) {
    return createPortal(
      <div className="flex justify-center items-center w-full h-full fixed inset-0 z-[100] bg-black bg-opacity-80">
        <div className="xl:w-[50vw] w-[90vw]">
          <div className="aspect-w-16 aspect-h-9 bg-orange">
            <div className="w-full h-full">
              <p className="text xl:text-vw-2xl text-vw-xl text-black text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                Загрузка . . .
              </p>

              <iframe
                className="w-full h-full relative z-10"
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>

        <button className="xl:w-vw-6 xl:h-vw-6 w-vw-20 h-vw-20 absolute xl:top-vw-14 xl:right-vw-14 top-vw-24 right-[5vw]" onClick={handleClose}>
          <img className="w-full filter invert" src={xMark} alt="крестик" />
        </button>
      </div>,
      document.getElementById('portal')
    )
  }

  return null
}

export { VideoModalContext, VideoModalProvider }
