import React, { useEffect } from 'react'
import { useSpring, animated as a, to } from 'react-spring'
import { useDrag } from '@use-gesture/react'
import { useMediaQuery } from 'react-responsive'

const Sticker = ({ width, sticker: { src, alt }, startXY, behindText, delay, style, ...props }) => {
  // thanks to this pen https://codesandbox.io/s/react-spring-logo-forked-hxir2

  const isDesktop = useMediaQuery({ query: '(min-width: 640px)' })
  const rMax = isDesktop ? 150 : 75

  // spring controls the sticker position
  const [{ x, y, cursor }, set] = useSpring(() => ({
    x: startXY ? startXY[0] : rMax / 2,
    y: startXY ? startXY[1] : rMax / 2,
    cursor: 'grab',
    config: { mass: 0.3, tension: 3000, friction: 6.5, precision: 0.5 }
  }))

  // creates a drag gesture
  const bind = useDrag(({ movement: [dX, dY], down }) => {
    const r = Math.sqrt(Math.pow(dX, 2) + Math.pow(dY, 2))
    // find point along radius with rMax length
    // see: https://math.stackexchange.com/q/1630886
    if (r > rMax) {
      dX *= rMax / r
      dY *= rMax / r
    }

    set({
      x: down ? dX : 0,
      y: down ? dY : 0,
      cursor: down ? 'grabbing' : 'grab',
      immediate: down
    })
  })

  // snap sticker towards the center on mount
  useEffect(() => void set({ x: 0, y: 0, delay: delay ? delay : 0 }), [set, delay])

  return (
    <div className="relative" style={{ width: width, touchAction: 'none', ...style }} {...bind()} {...props}>
      <a.div className="w-full" style={{
        transform: to([x, y], (x, y) => `translate(${x}px, ${y}px)`),
        cursor,
        touchAction: 'none'
      }}>
        <img
          className="w-full"
          src={src}
          alt={alt}
          onDragStart={e => e.preventDefault()}
        />
      </a.div>

      {behindText && (
        <div className="flex justify-center items-center w-full h-full text-orange absolute inset-0 z-[-1]">
          {behindText}
        </div>
      )}
    </div>
  )
}

export default Sticker
