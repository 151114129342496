import React, { useState, useEffect, useContext } from 'react'
import { useSprings, useTransition, animated as a } from 'react-spring'

import { BreakpointsContext } from '../../../../context/breakpoints.jsx'

import Sticker from '../../../../components/Sticker.jsx'

import keepmoscowTeam from '../../../../assets/img/team/webp/desktop/keepmoscow-team.webp'
import sticker7 from '../../../../assets/img/team/webp/desktop/sticker-7.webp'
import sticker9 from '../../../../assets/img/team/webp/desktop/sticker-9.webp'
import arrow from '../../../../assets/svg/big-arrow.svg'

import useInterpConfig from '../../../../hooks/interp.jsx'

const TeamSection = ({ team, spring: { st } }) => {
  const { b, setBrRef } = useContext(BreakpointsContext)
  const [interpConfig] = useInterpConfig()
  const [teamOrder, setTeamOrder] = useState(team.map((_, idx) => idx))
  const [teamText, setTeamText] = useState({
    name: team[0].name, 
    description: team[0].description
  })
  const [teamSprings, teamApi] = useSprings(team.length, idx => ({ x: 0, w: 0, z: 0 }))
  const textTransitions = useTransition(teamText, {
    from: { opacity: 0, translateX: 100 },
    enter: { opacity: 1, translateX: 0 },
    leave: { opacity: 0, translateX: -100 }
  })

  const handleButtonClick = (dir) => {
    let newOrder = teamOrder

    if (dir === 'next') {
      let fn = newOrder.pop()
      newOrder.unshift(fn)
    } else if (dir === 'prev') {
      let fn = newOrder.shift()
      newOrder.push(fn)
    }

    setTeamOrder(newOrder)
    changeTeamSprings(dir)
  }

  const changeTeamSprings = dir => {
    const idxs = {}

    for (const [idx, midx] of Object.entries(teamOrder)) {
      let nidx = Number(idx)

      if (nidx === 0) {
        idxs[midx] = {
          x: -30,
          z: -4,
          idx: nidx
        }
      } else {
        let invertedIdx = Math.abs((teamOrder.length - 1) - nidx)
        idxs[midx] = {
          x: -invertedIdx * 6.5,
          z: -invertedIdx,
          idx: nidx
        }
      }
    }

    setTeamText({
      name: team[teamOrder[0]].name,
      description: team[teamOrder[0]].description
    })

    const isBackwards = dir === 'prev' ? true : false
    const isBackwardsLast = (index) => {
      return teamOrder[teamOrder.length - 1] === index && isBackwards
    }
    const getMemberX = (index) => {
      let midx = idxs[index].idx;

      return (
        isBackwardsLast(index)
          ? 5
          : midx > 0 && midx < teamOrder.length - 3
            ? -19
            : idxs[index].x
      )
    }

    teamApi.start(idx => {
      return {
        x: getMemberX(idx),
        w: teamOrder[0] === idx ? 26.5 : 10,
        z: idxs[idx].z,
        onRest: () => teamApi.start(idx => isBackwardsLast(idx) && {
          x: 0
        })
      }
    })
  }

  useEffect(changeTeamSprings, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!team) {
    return null
  }

  return (
    <section ref={ref => setBrRef('team1', ref)} id="team" className="flex flex-col px-vw-12 pb-vw-28">
      <div className="flex items-stretch w-full pb-vw-10">
        <div className="flex items-center w-[29.8vw] px-vw-7">
          <p className="text text-vw-4xl normal-case">
            Команда<br/>профессиональных<br/>брокеров
          </p>
        </div>

        <div className="flex flex-col w-[62.7vw] relative ml-px pl-vw-20 pt-vw-20 pb-vw-10">
          <div className="flex flex-grow justify-end w-full h-[34.2vw] relative mb-vw-6">
            {teamSprings.map((styles, idx) => (
              <a.div key={idx} className="h-full absolute top-0 bottom-0" style={{
                width: styles.w.to(w => `${w}vw`),
                translateX: styles.x.to(x => `${x}vw`),
                zIndex: styles.z.to(z => idx === teamOrder[0] || idx === teamOrder[teamOrder.length - 1]
                  ? z : Math.ceil(z)
                )
              }}>
                <img
                  className="w-full h-full object-cover object-center"
                  src={team[idx].image.url}
                  alt={team[idx].image.alt}
                />
              </a.div>
            ))}
          </div>

          <div className="flex justify-between items-start w-full">
            <div className="w-1/2 h-vw-20 relative">
              {textTransitions((styles, item) => (
                <a.div className="w-full absolute inset-0 pl-vw-3 pr-vw-10" style={styles}>
                  <h4 className="text text-vw-2xl text-orange normal-case mb-vw-2">
                    {item.name}
                  </h4>
                  <p className="text-vw-lg font-extralight">
                    {item.description}
                  </p>
                </a.div>
              ))}
            </div>

            <div className="flex justify-between w-1/2 pt-vw-8 pl-vw-10 pr-vw-20">
              <button className="flex justify-center items-center w-vw-20" onClick={() => handleButtonClick('prev')}>
                <img
                  className="w-vw-5 filter invert transform rotate-180"
                  src={arrow}
                  alt="стрелка назад"
                />
              </button>

              <button className="flex justify-center items-center w-vw-20" onClick={() => handleButtonClick('next')}>
                <img
                  className="w-vw-5 filter invert"
                  src={arrow}
                  alt="стрелка вперед"
                />
              </button>
            </div>
          </div>

          <div className="absolute top-vw-10 left-vw-20 right-vw-12 -bottom-vw-16 z-[-11] bg-gray-dark"></div>
        </div>
      </div>

      <div ref={ref => setBrRef('team2', ref)} className="flex items-stretch w-full pt-vw-28">
        <div className="flex flex-col w-[29.8vw]">
          <img className="max-w-none w-[62.7vw] mt-vw-32 ml-vw-4" src={keepmoscowTeam} alt="" />

          <a.p className="text-vw-4xl mt-vw-32 ml-vw-7" style={{
            opacity: st.to(interpConfig([
              [b.get('contact', '-60%'), 1],
              [b.get('contact', '-20%'), 0]
            ])),
            translateY: st.to(interpConfig([
              [b.get('contact', '-60%'), 0],
              [b.get('contact', '-20%'), -5]
            ])).to(y => `${y}vw`)
          }}>
            Чтобы вы<br />получали только<br /><span className="text-orange">удовольствие</span><br />от процесса
          </a.p>
        </div>

        <div className="flex flex-col justify-between w-[34.86vw] px-vw-7">
          <p className="text-vw-4xl">
            Над каждой<br />
            заявкой работает<br />
            <span className="text-orange">целая команда</span> 
          </p>
          
          <a.div className="relative ml-auto -mr-vw-3" style={{
            translateX: st.to(interpConfig([
              [b.get('contact', '-60%'), 14],
              [b.get('contact', '-20%'), -4.7]
            ])).to(x => `${x}vw`)
          }}>
            <Sticker
              width="21vw"
              sticker={{
                src: sticker7,
                alt: 'стикер 6'
              }}
              style={{
                transform: 'rotate(12deg)'
              }}
            />

            <Sticker
              className="absolute left-vw-10 top-vw-1"
              width="15vw"
              sticker={{
                src: sticker9,
                alt: 'стикер keepmoscow smile'
              }}
            />
          </a.div>
        </div>

        <div className="flex flex-grow items-center pl-vw-10">
          <a.p className="text-vw-4xl mb-vw-8" style={{
            translateX: st.to(interpConfig([
              [b.get('team1', '-5%'), 10],
              [b.get('team2', '-10%'), 0],
            ])).to(x => `${x}vw`),
            opacity: st.to(interpConfig([
              [b.get('team1', '-5%'), 0],
              [b.get('team2', '-10%'), 1],
            ]))
          }}>
            Мы рядом с вами<br />на всех этапах
          </a.p>
        </div>
      </div>
    </section>
  )
}

export default TeamSection
