import React from 'react'
import { useTransition, animated as a } from 'react-spring'

import logoPaths from './logoPaths'

const AnimatedLogo = ({ handleAnimationEnd }) => {
  const transitions = useTransition(logoPaths, {
    from: {
      x: -200,
      fill: '#fff'
    },
    enter: item => async next => {
      await next({ x: 0 })
      await next({ fill: '#EB682A' })
    },
    delay: item => item.id * 125,
    onRest: () => {
      if (handleAnimationEnd) handleAnimationEnd()
    }
  })

  return (
    <div className="flex justify-center">
      {transitions((styles, item) => (
        <svg className="h-vw-48 mr-vw-2" viewBox={item.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
          <a.path d={item.path} style={{ ...styles }} />
        </svg>
      ))}
    </div>
  )
}

export default AnimatedLogo
